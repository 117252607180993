<app-menu>
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 demo-table">
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Requête</th>
      <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>
    <ng-container matColumnDef="url">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <button mat-button color="primary" (click)="requestUpdate(element.url)">LANCER</button>

        <ng-container class="example-form" *ngIf="element.name=='checkCoordinates'">
          <mat-form-field appearance="fill">
            <mat-label>Start id</mat-label>
            <input [(ngModel)]="startId" matInput placeholder="1" value=1>
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>End Id</mat-label>
            <input [(ngModel)]="endId" matInput placeholder="9" value=5>
          </mat-form-field>
        </ng-container>
      </td>
    </ng-container>
    <ng-container matColumnDef="start">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let d">
        <button mat-button color="primary" (click)="requestUpdate(d.url)">LANCER</button>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

</app-menu>
