<ng-container>
                          <td style="max-width: 7rem;">{{educateurBr}}</td>
                          <td class="col" style="max-width: 4rem;">28,5</td>
                        <td>{{educateurNom +" "+ educateurPrenom}}</td>


<td cdkDropList
    [cdkDropListData]="this.educateurPrestations"
    class="example-list"

    (cdkDropListDropped)="drop($event,id,'2021-07-12')"

>
<ng-container *ngFor="let presta of educateurPrestations let j = index"  >
  <ng-container >
  <app-prestation *ngIf="presta.idEducateur === id && presta.datePrestation == '2021-07-12'"

                  [nomBeneficiaire]="presta.nomBeneficiaire" cdkDrag [cdkDragData]="presta"

  ></app-prestation></ng-container>

</ng-container>

</td>




  <td cdkDropList
      [cdkDropListData]="this.educateurPrestations"
      class="example-list"

      (cdkDropListDropped)="drop($event,id,'2021-07-13')"  >
    <ng-container *ngFor="let presta of educateurPrestations let j = index"  >
      <ng-container >
        <app-prestation *ngIf="presta.idEducateur === id  && presta.datePrestation == '2021-07-13'"

                        [nomBeneficiaire]="presta.nomBeneficiaire" cdkDrag [cdkDragData]="presta"

        >


        </app-prestation></ng-container>

    </ng-container>

  </td>
  <td cdkDropList
      [cdkDropListData]="this.educateurPrestations"
      class="example-list"

      (cdkDropListDropped)="drop($event,id,'2021-07-14')"

  >
    <ng-container *ngFor="let presta of educateurPrestations let j = index"  >
      <ng-container >
        <app-prestation *ngIf="presta.idEducateur === id  && presta.datePrestation == '2021-07-14'"

                        [nomBeneficiaire]="presta.nomBeneficiaire" cdkDrag [cdkDragData]="presta"

        ></app-prestation></ng-container>

    </ng-container>

  </td>
  <td cdkDropList
      [cdkDropListData]="this.educateurPrestations"
      class="example-list"

      (cdkDropListDropped)="drop($event,id,'2021-07-15')"

  >
    <ng-container *ngFor="let presta of educateurPrestations let j = index"  >
      <ng-container >
        <app-prestation *ngIf="presta.idEducateur === id  && presta.datePrestation == '2021-07-15'"

                        [nomBeneficiaire]="presta.nomBeneficiaire" cdkDrag [cdkDragData]="presta"

        ></app-prestation></ng-container>

    </ng-container>

  </td>
  <td cdkDropList
      [cdkDropListData]="this.educateurPrestations"
      class="example-list"

      (cdkDropListDropped)="drop($event,id,'2021-07-16')"

  >
    <ng-container *ngFor="let presta of educateurPrestations let j = index"  >
      <ng-container >
        <app-prestation *ngIf="presta.idEducateur === id  && presta.datePrestation == '2021-07-16'"

                        [nomBeneficiaire]="presta.nomBeneficiaire" cdkDrag [cdkDragData]="presta"

        ></app-prestation></ng-container>

    </ng-container>

  </td>


  <td cdkDropList
      [cdkDropListData]="this.educateurPrestations"
      class="example-list"

      (cdkDropListDropped)="drop($event,id,'2021-07-17')"

  >
    <ng-container *ngFor="let presta of educateurPrestations let j = index"  >
      <ng-container >
        <app-prestation *ngIf="presta.idEducateur === id  && presta.datePrestation == '2021-07-17'"

                        [nomBeneficiaire]="presta.nomBeneficiaire" cdkDrag [cdkDragData]="presta"

        ></app-prestation></ng-container>

    </ng-container>

  </td>


  <td cdkDropList
      [cdkDropListData]="this.educateurPrestations"
      class="example-list"

      (cdkDropListDropped)="drop($event,id,'2021-07-18')"

  >
    <ng-container *ngFor="let presta of educateurPrestations let j = index"  >
      <ng-container >
        <app-prestation *ngIf="presta.idEducateur === id  && presta.datePrestation == '2021-07-18'"

                        [nomBeneficiaire]="presta.nomBeneficiaire" cdkDrag [cdkDragData]="presta"

        ></app-prestation></ng-container>

    </ng-container>

  </td>


</ng-container>



