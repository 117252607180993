import {Component, OnInit} from '@angular/core';
import {EducateurService} from "../services/educateur.service";
import {Subscription} from "rxjs";
import {PrestationService} from "../services/prestation.service";
@Component({
  selector: 'app-tableau-horaire',
  templateUrl: './tableau-horaire.component.html',
  styleUrls: ['./tableau-horaire.component.scss']
})
export class TableauHoraireComponent implements OnInit {




  educateurs: any[];
  educateurSubscription: Subscription;
  prestations: any[];
  prestationSubscription: Subscription;
  constructor(private educateurService:EducateurService,private prestationService: PrestationService) {
  }

  ngOnInit(): void {
    this.educateurSubscription = this.educateurService.educateurSubject.subscribe(
      (educateurs:any[])=>{
        this.educateurs = educateurs;
      }
    )
    this.educateurService.emitEducateurs();


    this.prestationSubscription = this.prestationService.prestationSubject.subscribe(
      (prestations: any[])=>{
        this.prestations = prestations;
      }
    )
    this.prestationService.emitPrestationSubject();
    this.educateurService.setEducateur(this.prestations);
  }

}
