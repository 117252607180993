import { Component, OnInit } from '@angular/core';
import {SchedulerService} from "../services/scheduler.service";
import { ActivatedRoute } from '@angular/router';
import {addWeeks, format, getWeek, endOfWeek, startOfWeek, subWeeks} from 'date-fns'
import _ from "lodash";
import {WeekPeriod} from "../../model/week-period";
import {Period} from "../interfaces/period";




@Component({
  selector: 'app-scheduler',
  templateUrl: './scheduler.component.html',
  styleUrls: ['./scheduler.component.scss']
})
export class SchedulerComponent implements OnInit {
  educateurs;
  selectedDate;
  selectedWeek:number;
  startOfWeek;
  endOfWeek;
  period: Period;
  isLoaded: boolean;
  constructor(private route: ActivatedRoute,private schedulerService: SchedulerService)
  {
    this.schedulerService = schedulerService;
    this.selectedDate = new Date( this.route.snapshot.paramMap.get('date'));
    this.startOfWeek=startOfWeek(this.selectedDate, { weekStartsOn: 1 });
    this.endOfWeek=endOfWeek(this.selectedDate, { weekStartsOn: 1 });
   this.period = new WeekPeriod(this.startOfWeek);
  }
  ngOnInit(): void {
    this.isLoaded=false;
  this.updateDate();
  }

  public  addWeek()
  {
    this.selectedDate=addWeeks(this.selectedDate,1);
    this.updateDate();
  }
  public subWeek()
  {
    this.selectedDate=subWeeks(this.selectedDate,1);
    this.updateDate();
  }

  private  updateDate()
  {
    this.isLoaded=false;
    this.selectedWeek = getWeek(this.selectedDate, {
      weekStartsOn: 1,
      firstWeekContainsDate: 4
    });
    this.startOfWeek=startOfWeek(this.selectedDate, { weekStartsOn: 1 });
    this.endOfWeek=endOfWeek(this.selectedDate, { weekStartsOn: 1 });
    this.period = new WeekPeriod(this.startOfWeek)
    window.history.replaceState({},'',this.period.getFirstDayOfPeriod().getDate())
    this.schedulerService.fetchEducateurToLoadInScheduler(format(this.startOfWeek,'yyyy-MM-dd')).then((data)=>{
      this.educateurs= _.sortBy(data,['ORDRE_PLANNING']);
    });
     this.schedulerService.fetchPrestationsBetweenTwoDates(this.period.getFirstDayOfPeriod().getDate(),this.period.getLastDayOfPeriod().getDate()).then(()=>this.isLoaded=true);
  }

}
