import {Component, Input, OnInit} from '@angular/core';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from "@angular/cdk/drag-drop";
import {SchedulerService} from "../services/scheduler.service";
import {Period} from "../interfaces/period";

@Component({
  selector: 'app-scheduler-line',
  templateUrl: './scheduler-line.component.html',
  styleUrls: ['./scheduler-line.component.scss']
})
export class SchedulerLineComponent implements OnInit {
  @Input() educateurFirstName: string;
  @Input() educateurId:number;
  @Input() period:Period;
weekEvents=[];
  isLoaded = false;
  constructor(private schedulerService: SchedulerService) {
    this.schedulerService = schedulerService;
  }

  ngOnInit(): void {
    this.weekEvents=this.schedulerService.getPrestationsByEducateurId(this.educateurId);
    this.isLoaded = true;
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
  }

  filterByDay(date) {
    let dayEvent=[];
    for (let weekEvent of this.weekEvents) {
      if(this.isSameDate(date,weekEvent.getDate()))
      {
        dayEvent.push(weekEvent);
      }
    }
    return dayEvent;
  }
  isSameDate(expected,given)
  {
    return expected == given;
  }
}
