<div class="scheduler-day-container"
     cdkDropList  [cdkDropListData]="listOfEvents" (cdkDropListDropped)="drop($event)">
<ng-container *ngFor="let item of listOfEvents" >
  <mat-card  cdkDrag>
  <mat-card-subtitle>Prestation</mat-card-subtitle>
  <mat-card-title>{{item.getName()}}</mat-card-title>
  <mat-card-content>
    <p>{{item.getHoraire()}}</p>
  </mat-card-content>
</mat-card>
</ng-container>
</div>
