import {format} from "date-fns";

export class Day {
  private date: Date;
  constructor(date:Date) {
    this.date = date;
  }
 public  getDate() {
    return format(this.date,'yyyy-MM-dd');
  }
}
