import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {format} from "date-fns";

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  today= format(Date.now(),'yyyy-MM-dd');
  menulist = [
    {"name":"Horaire","value":"/horaire/"+this.today
    },
    {"name":"Bénéficiaires","value":null},
    {"name":"Personnel",
      "sub":[
        {"name":"Educateurs"},
        {"name":"Bénévoles"},
        {"name":"Contrats"},
        {"name":"Jours fériés"}
      ]},
    {"name":"Rapport",
      "sub":[
        {"name":"KM","value":"/generate-report-km"},
        {"name":"RA"},
        {"name":"Facturation","value":"/generate-facture"},
        {"name":"Refus"},
        {"name":"Congés et OUT"}
      ]},
    {"name":"Ressources"},
  ]
  constructor(private route:Router) { }

  ngOnInit(): void {
  }

}
