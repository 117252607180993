<div class="login-container">
  <img class="login-img" src="./assets/logo-wallopoly-header.jpg" style='height: 100%; width: 100%; object-fit: contain' alt="wallopoly-logo"/>
  <form (ngSubmit)="onSubmit(fm)" #fm="ngForm" id="login-form" *ngIf="isForgetPassWordHidden">
    <div class="login-container__form-group">
      <label class="login-label" for="login">Email</label>
      <input id="login" type="text" class="form-control login-input" [(ngModel)]="username" name="username">

      <label class="login-label" for="password">Mot de passe</label>
      <input id="password" type="password" class="form-control login-input" [(ngModel)]="password" name="password">

      <a href="#" class="login-forgotten-password" (click)="forgottenPassWord()">Mot de passe perdu ?</a>

      <div class="buttons-row">
        <button type="submit" class="buttons-row__button" mat-raised-button  (click)="login()">Se connecter</button>
      </div>

    </div>
  </form>
  <app-forget-pass-word id="forgetPassWord" *ngIf="!isForgetPassWordHidden"></app-forget-pass-word>
</div>
