<table class="table table-bordered ">
  <thead>
      <tr>
          <th scope="col">
             <strong><- Semaine 16: du 19 Avril au 25 Avril -></strong>
          </th>
      </tr>
  </thead>
  <tbody>
  <p>COUCOU</p>
      <tr>
          <td>
              <table class="table table-bordered table-striped ">
                  <thead>
                      <tr>
                          <th class="entete">Région</th>
                          <th class="entete">H.SEM</th>
                          <th class="entete">Educateur</th>
                          <th>Lundi&nbsp;&nbsp;&nbsp;</th>
                          <th>Mardi&nbsp;&nbsp;&nbsp;</th>
                          <th>Mercredi</th>
                          <th>Jeudi&nbsp;&nbsp;&nbsp;</th>
                          <th>Vendredi</th>
                          <th>Samedi&nbsp;&nbsp;</th>
                          <th>Dimanche</th>
                      </tr>
                  </thead>
                  <tbody id="table-educ" cdkDropListGroup>

                        <tr my-tr
                          *ngFor="let educateur of educateurs; let i = index"
                          [educateurNom]="educateur.nom"
                          [educateurPrenom]="educateur.prenom"
                          [educateurBr]="educateur.br"
                          [id]="educateur.id"
                            [educateurPrestations]="educateur.prestations"

                             ></tr>


<!--                      <tr *ngFor="let educ of educList">-->
<!--                          <td style="max-width: 7rem;">{{educ.br}}</td>-->
<!--                          <td class="col" style="max-width: 4rem;">28,5</td>-->
<!--                          <td>{{educ.nom +" "+ educ.prenom}}</td>-->
<!--                          <td class="col" id="{{educ.prenom +1}}" >-->
<!--                              <ng-container *ngFor="let prestation of prestationList ">-->

<!--                                <div class="card text-white bg-primary mb-1" id="De RyckereAymeric14:00:00"-->
<!--                                  draggable="true"  *ngIf="prestation.nomEducateur === educ.nom && prestation.datePrestation ==='2021-07-12'" >-->
<!--                                  <div class="card-header">{{prestation.heureDebut}}</div>-->
<!--                                  <div class="card-body">-->
<!--                                      <h5 class="card-title">{{prestation.nomBeneficiaire}} </h5>-->

<!--                                  </div>-->
<!--                              </div>-->
<!--                              </ng-container>-->


<!--                          </td>-->
<!--                          <td class="col" id="{{educ.prenom +2}}">-->
<!--                            <ng-container *ngFor="let prestation of prestationList ">-->
<!--                              <div class="card text-white bg-primary mb-1" id="De RyckereAymeric14:00:00"-->
<!--                                   draggable="true"  *ngIf="prestation.nomEducateur === educ.nom && prestation.datePrestation ==='2021-07-13'" >-->
<!--                                <div class="card-header">{{prestation.heureDebut}}</div>-->
<!--                                <div class="card-body">-->
<!--                                  <h5 class="card-title">{{prestation.nomBeneficiaire}} </h5>-->

<!--                                </div>-->
<!--                              </div>-->
<!--                            </ng-container>-->
<!--                          </td>-->
<!--                          <td class="col" id="{{educ.prenom +3}}">-->
<!--                            <ng-container *ngFor="let prestation of prestationList ">-->
<!--                              <div class="card text-white bg-primary mb-1" id="De RyckereAymeric14:00:00"-->
<!--                                   draggable="true"  *ngIf="prestation.nomEducateur === educ.nom && prestation.datePrestation ==='2021-07-14'" >-->
<!--                                <div class="card-header">{{prestation.heureDebut}}</div>-->
<!--                                <div class="card-body">-->
<!--                                  <h5 class="card-title">{{prestation.nomBeneficiaire}} </h5>-->

<!--                                </div>-->
<!--                              </div>-->
<!--                            </ng-container>-->
<!--                          </td>-->
<!--                          <td class="col" id="{{educ.prenom +4}}">-->
<!--                            <ng-container *ngFor="let prestation of prestationList ">-->
<!--                            <div class="card text-white bg-primary mb-1" id="De RyckereAymeric14:00:00"-->
<!--                                 draggable="true"  *ngIf="prestation.nomEducateur === educ.nom && prestation.datePrestation ==='2021-07-15'" >-->
<!--                              <div class="card-header">{{prestation.heureDebut}}</div>-->
<!--                              <div class="card-body">-->
<!--                                <h5 class="card-title">{{prestation.nomBeneficiaire}} </h5>-->

<!--                              </div>-->
<!--                            </div>-->
<!--                          </ng-container>-->
<!--                          </td>-->
<!--                          <td class="col" id="{{educ.prenom +5}}">-->
<!--                            <ng-container *ngFor="let prestation of prestationList ">-->
<!--                              <div class="card text-white bg-primary mb-1" id="De RyckereAymeric14:00:00"-->
<!--                                   draggable="true"  *ngIf="prestation.nomEducateur === educ.nom && prestation.datePrestation ==='2021-07-16'" >-->
<!--                                <div class="card-header">{{prestation.heureDebut}}</div>-->
<!--                                <div class="card-body">-->
<!--                                  <h5 class="card-title">{{prestation.nomBeneficiaire}} </h5>-->

<!--                                </div>-->
<!--                              </div>-->
<!--                            </ng-container>-->
<!--                          </td>-->
<!--                          <td class="col" id="{{educ.prenom +6}}">-->
<!--                            <ng-container *ngFor="let prestation of prestationList ">-->
<!--                              <div class="card text-white bg-primary mb-1" id="De RyckereAymeric14:00:00"-->
<!--                                   draggable="true"  *ngIf="prestation.nomEducateur === educ.nom && prestation.datePrestation ==='2021-07-17'" >-->
<!--                                <div class="card-header">{{prestation.heureDebut}}</div>-->
<!--                                <div class="card-body">-->
<!--                                  <h5 class="card-title">{{prestation.nomBeneficiaire}} </h5>-->

<!--                                </div>-->
<!--                              </div>-->
<!--              .              </ng-container>-->
<!--                          </td>-->
<!--                          <td class="col" id="{{educ.prenom +0}}">-->
<!--                            <ng-container *ngFor="let prestation of prestationList ">-->
<!--                              <div class="card text-white bg-primary mb-1" id="De RyckereAymeric14:00:00"-->
<!--                                   draggable="true"  *ngIf="prestation.nomEducateur === educ.nom && prestation.datePrestation ==='2021-07-18'" >-->
<!--                                <div class="card-header">{{prestation.heureDebut}}</div>-->
<!--                                <div class="card-body">-->
<!--                                  <h5 class="card-title">{{prestation.nomBeneficiaire}} </h5>-->

<!--                                </div>-->
<!--                              </div>-->
<!--                            </ng-container>-->
<!--                          </td>-->
<!--                      </tr>-->

                      <tr id="aAttribuer">
                          <th colspan="3" scope="row">A Attribuer</th>
                          <td ondrop="drop_handler(event)" ondragover="dragover_handler(event)"></td>
                          <td ondrop="drop_handler(event)" ondragover="dragover_handler(event)"></td>
                          <td ondrop="drop_handler(event)" ondragover="dragover_handler(event)"></td>
                          <td ondrop="drop_handler(event)" ondragover="dragover_handler(event)"></td>
                          <td ondrop="drop_handler(event)" ondragover="dragover_handler(event)"></td>
                          <td ondrop="drop_handler(event)" ondragover="dragover_handler(event)"></td>
                          <td ondrop="drop_handler(event)" ondragover="dragover_handler(event)"></td>
                      </tr>
                      <tr>
                          <th colspan="3" scope="row">Refusé</th>
                          <td ondrop="drop_handler(event)" ondragover="dragover_handler(event)"></td>
                          <td ondrop="drop_handler(event)" ondragover="dragover_handler(event)"></td>
                          <td ondrop="drop_handler(event)" ondragover="dragover_handler(event)"></td>
                          <td ondrop="drop_handler(event)" ondragover="dragover_handler(event)"></td>
                          <td ondrop="drop_handler(event)" ondragover="dragover_handler(event)"></td>
                          <td ondrop="drop_handler(event)" ondragover="dragover_handler(event)"></td>
                          <td ondrop="drop_handler(event)" ondragover="dragover_handler(event)"></td>
                      </tr>
                      <tr>
                          <th colspan="3" scope="row">Annulé</th>
                          <td ondrop="drop_handler(event)" ondragover="dragover_handler(event)"></td>
                          <td ondrop="drop_handler(event)" ondragover="dragover_handler(event)"></td>
                          <td ondrop="drop_handler(event)" ondragover="dragover_handler(event)"></td>
                          <td ondrop="drop_handler(event)" ondragover="dragover_handler(event)"></td>
                          <td ondrop="drop_handler(event)" ondragover="dragover_handler(event)"></td>
                          <td ondrop="drop_handler(event)" ondragover="dragover_handler(event)"></td>
                          <td ondrop="drop_handler(event)" ondragover="dragover_handler(event)"></td>
                      </tr>
                  </tbody>
              </table>
          </td>
          <td>
              <table class="table table-bordered ">
                  <thead>
                      <tr>
                          <th id="presta" scope="col" class="col-1">Prestations</th>
                      </tr>
                  </thead>
                  <tbody id="table-prestation">
                      <tr>
                          <td class="d-flex flex-row" id="liste-garde" style="width: 100%;">
                              <div id="addGardeForm" style="display: none;">
                                  <form method="get" action="addGarde()" id="login-form" class="login-form"
                                      autocomplete="off" role="main">
                                      <h1>Ajout d'une garde</h1>
                                      <div>
                                          <label class="label-email">
                                              <input id="formnom" type="text" class="text" name="nom"
                                                  placeholder="Nom" tabindex="1" required />
                                          </label>
                                      </div>
                                      <div>
                                          <label class="label-email">
                                              <input id="formprenom" type="text" class="text" name="prénom"
                                                  placeholder="Prénom" tabindex="1" required />
                                          </label>
                                      </div>
                                      <div>
                                          <label class="label-email">
                                              <input id="formremarque" type="text" class="text" name="remarque"
                                                  placeholder="Remarques" tabindex="1" />
                                          </label>
                                      </div>
                                      <div> <input type="time" id='select_heure_debut' name="heure_debut" required>
                                          <small>Heure début &nbsp;</small>
                                      </div>
                                      <div>
                                          <input type="time" id='select_heure_fin' name="heure_fin" required>
                                          <small>Heure fin &nbsp;&nbsp;&nbsp;&nbsp; &nbsp; </small>
                                      </div>
                                      <input type="checkbox" name="show-password" class="show-password a11y-hidden"
                                          id="show-password" tabindex="3" />
                                      <label class="label-show-password" for="show-password">
                                          <span>Bleu</span>
                                      </label>
                                      <input type="button" value="Ajouter" id="addGarde" />
                                      <input type="button" value="Annuler" id="annulerGarde" />
                                  </form>
                              </div>
                          </td>
                      </tr>
                  </tbody>
              </table>
              <table class="table table-bordered ">
                  <thead>
                      <tr>
                          <th id="showVa" scope="col" class="col-1">VA/OUT/MALADIES</th>
                      </tr>
                  </thead>
                  <tbody id="table-prestation">
                      <tr>
                          <td id="liste-va" class="d-flex flex-row" style="width: 100%;">
                              <div id="addVaForm" class="form-group" style="display: none;">
                                  <form method="get" action="addGarde()" id="login-form" class="login-form"
                                      autocomplete="off" role="main">
                                      <h1>Nouveau VA/OUT/MALADIE</h1>
                                      <div>
                                          <label class="label-email">
                                              <select id="formeducateur" type="text"
                                                  class="form-control form-control-sm" name="nom"
                                                  placeholder="Educateur" tabindex="1" required>
                                                  <option value="none">educateur</option>
                                                  <option value="annabelle">annabelle</option>
                                                  <option value="nicolas">Nicolas</option>
                                                  <option value="francois">François</option>
                                              </select>
                                          </label>
                                      </div>
                                      <div>
                                          <label class="label-email">
                                              <select id="formtype" type="text" class="form-control form-control-sm"
                                                  name="nom" placeholder="Educateur" tabindex="1" required>
                                                  <option value="va">VA</option>
                                                  <option value="out">OUT</option>
                                                  <option value="maladie">Maladie</option>
                                              </select>
                                          </label>
                                      </div>
                                      <div> <label for="start">Du
                                              <input type="date" id="vastart" name="trip-start" value="2018-07-22"
                                                  min="2021-04-19" max="2021-04-25">
                                          </label>
                                          <label for="start">Au
                                              <input type="date" id="vaend" name="trip-start" value="2018-07-22"
                                                  min="2021-04-19" max="2021-04-25">
                                          </label>
                                      </div>
                                      <div> <button type="button" id="addVa" class="btn btn-success">Valider</button>
                                          <button id="annulerVa" type="button" class="btn btn-danger">Annuler</button>
                                      </div>
                                  </form>
                              </div>
                          </td>
                      </tr>
                      <tr>
                          <td class="d-flex justify-content-center" style="width: 100%;">
                              <button type="button" class="btn btn-success btn"
                                  style="margin-right:15% ; ">Valider</button>
                              <button type="button" class="btn btn-danger btn">Annuler</button>
                          </td>
                      </tr>
                  </tbody>
              </table>
          </td>
      </tr>
  </tbody>
</table>
