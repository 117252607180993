<app-menu>
<div class="scheduler-container">
  <div class="scheduler-container__title"><h1><button (click)="subWeek()"><mat-icon>keyboard_arrow_left</mat-icon></button>Semaine: {{this.selectedWeek}}
    <button (click)="addWeek()"><mat-icon>keyboard_arrow_right</mat-icon></button></h1></div>
  <div class="scheduler-container__content">
    <div class="scheduler-container__content__week">

      <p  class="scheduler-container__content__day"></p>
      <p class="scheduler-container__content__day" *ngFor="let day of period.getDaysOfPeriod()"><strong>{{day.getDate()}}</strong></p>
    </div>

    <mat-spinner class="spinner"
                 mode="indeterminate"
                 *ngIf="!isLoaded"
                 diameter="40"></mat-spinner>
  <div cdkDropListGroup *ngIf="isLoaded">
    <app-scheduler-line *ngFor="let educateur of educateurs " [educateurFirstName]="educateur.personne.PRENOM" [educateurId]="educateur.personne.ID"   [period]="period" ></app-scheduler-line>
  </div>

    <div class="scheduler-container__content__week">

    </div>
    <div>

    </div>
</div>
</div>
</app-menu>
