<mat-card class="beneficiaire-card"
          [ngClass]="{
   'bapCard': isBap,
   'repitCard': !isBap
}"
>

  <h6><strong>{{beneficiary.lastName}}</strong> {{beneficiary.firstName}}
    <strong> ({{beneficiary.contact}})</strong></h6>
  <div class="button-container">
    <button mat-stroked-button >
      <mat-icon>attach_money</mat-icon>
    </button>
    <button mat-stroked-button >
      <mat-icon>email</mat-icon>
    </button>
    <button mat-stroked-button (click)="generateAndDownloadInvoice()">
      <mat-icon>picture_as_pdf</mat-icon>
    </button>
  </div>

</mat-card>
