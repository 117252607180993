import { Injectable } from '@angular/core';
import {ConfigLoaderService} from "./config-loader.service";
import {HttpClient} from "@angular/common/http";
import {Config} from "../models/ConfigModel";

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private config: Config;

  constructor(configLoader: ConfigLoaderService,private http: HttpClient) {
    this.config = configLoader.getConfigData();
  }

  fetchCountOfWrongFormattedAddresses() {

    return this.http.get<any>(this.config.host+'/api/address/fetchCountOfAddressesWrongFormatted').toPromise();
  }
}
