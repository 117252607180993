<div class="example-container" >
  <mat-toolbar color="primary" class="example-toolbar">
    <button mat-icon-button (click)="snav.toggle()"><mat-icon>menu</mat-icon></button>
    <a [routerLink]="['/main']"> <img class="login-img" src="./assets/logo-wallopoly-header.png" style='height: 64px; width: 160px; object-fit: contain' alt="wallopoly-logo"/></a>
  </mat-toolbar>

  <mat-sidenav-container class="example-sidenav-container"
                         [style.marginTop.px]="0">
    <mat-sidenav #snav [mode]="'side'"
                 [fixedInViewport]="false" fixedTopGap="56" opened="true">
      <mat-nav-list >
<app-navbar ></app-navbar>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content class="sidebar-container">
      <ng-content></ng-content>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>



