import {Component, Input, OnInit} from '@angular/core';
import {Beneficiary} from "../../model/Beneficiary";
import {InvoiceService} from "../services/invoice.service";

@Component({
  selector: 'app-benefeciary-card',
  templateUrl: './benefeciary-card.component.html',
  styleUrls: ['./benefeciary-card.component.scss']
})

export class BenefeciaryCardComponent implements OnInit {
  @Input() isBap: boolean;
  @Input() beneficiary: Beneficiary;
  @Input() month: number;
  @Input() year: number;
private invoiceService : InvoiceService;
  constructor(invoiceService: InvoiceService) {
    this.invoiceService = invoiceService;
  }

  ngOnInit(): void {
  }

  public generateAndDownloadInvoice() {

    this.invoiceService.generateAndDownloadPrestationInvoice(this.beneficiary,this.year,this.month,this.isBap);
  }

}
