import { Component, OnInit } from '@angular/core';
import {NotificationService} from "../services/notification.service";
import {AdminService} from "../services/admin.service";


@Component({
  selector: 'app-user-main-interface',
  templateUrl: './user-main-interface.component.html',
  styleUrls: ['./user-main-interface.component.scss']
})
export class UserMainInterfaceComponent implements OnInit {

  countOfAddressesWrongFormated:number;
   requesting: boolean;
  private adminService: AdminService;
   wrongEncodedAddresses;
  constructor(private notificationService: NotificationService,adminService:AdminService )
  {
    this.notificationService = notificationService;
    this.adminService = adminService;
  }

  ngOnInit(): void {

    this.notificationService.fetchCountOfWrongFormattedAddresses().then((data)=>
    {
      console.log(data);
      this.countOfAddressesWrongFormated = data;
    });
    this.fetchWrongEncodedAddresses();
  }
  fetchWrongEncodedAddresses()
  {
    this.requesting = true;
    this.adminService.fetchWrongFormattedAddresses().then((data)=>
    {
      this.wrongEncodedAddresses=data;
      this.requesting = false;
    })
  }

  getTotalOfElementsToDisplay(array :[])
  {
    return array.length;
  }
}
