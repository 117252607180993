import {Injectable} from "@angular/core";
import {Config} from "../models/ConfigModel";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class ConfigLoaderService {

  private config: Config;
  constructor(private http: HttpClient) {
    this.config = new Config();
  }

  public getConfigData(): Config {
    return this.config;
  }
  load(){
    return this.http.get("./assets/env.json")
      .toPromise()
      .then((settings: Config) => {
        this.config = settings;
        return settings;
      });
  }
}
