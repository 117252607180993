

    <div class="card text-white bg-primary mb-1" id="De RyckereAymeric14:00:00"

         *ngIf="nomBeneficiaire ===nomBeneficiaire && nomBeneficiaire ===nomBeneficiaire"

    >
      <div class="card-header" >{{nomBeneficiaire}}</div>
      <div class="card-body">
        <h5 class="card-title">{{nomBeneficiaire}} </h5>

      </div>
    </div>




