import { Component, OnInit,Input } from '@angular/core';
import {EducateurService} from "../services/educateur.service";
import {Subscription} from "rxjs";
import {PrestationService} from "../services/prestation.service";
import {CdkDragDrop, moveItemInArray,transferArrayItem} from "@angular/cdk/drag-drop";
import {PrestationComponent} from "../prestation/prestation.component";


@Component({
  selector: '[my-tr]',
  templateUrl: './educateur.component.html',
  styleUrls: ['./educateur.component.scss']
})
export class EducateurComponent implements OnInit {

   @Input() educateurNom:string;
   @Input() educateurPrenom:string;
   @Input() educateurBr: string;
   @Input() id:number;
   @Input()educateurPrestations:any[]

  prestations: any[];
  prestationSubscription: Subscription;



  constructor(private educateurService: EducateurService, private prestationService: PrestationService) { }

  ngOnInit(): void {

  }

  getNom(){
    return this.educateurNom;
  }
  getPrenom(){
    return this.educateurPrenom;
  }

  getBr(){
    return this.educateurBr;

  }

  getPrestations(){
    return this.educateurPrestations;
  }

  drop(event: CdkDragDrop<any[]>, id:number, data:string) {

    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);

    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    // console.log(event.item.data.datePrestation);
    console.log(event.container.data);
      this.educateurService.setPrestation(id,event.item.data.id,data);
      // this.prestationService.setPrestation(this.Prestation)this.prestationService.prestationSubject.
  this.educateurService.emitEducateurs();

    }

  }


}
