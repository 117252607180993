import {Component, Input, OnInit} from '@angular/core';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from "@angular/cdk/drag-drop";
import {PrestationEvent} from "../prestation-event";
import {compareAsc, parseISO} from "date-fns";

@Component({
  selector: 'app-scheduler-day',
  templateUrl: './scheduler-day.component.html',
  styleUrls: ['./scheduler-day.component.scss']
})
export class SchedulerDayComponent implements OnInit {
  @Input() dayEvent:any;
     listOfEvents:PrestationEvent[];
  constructor() {

  }

  ngOnInit(): void {
   this.listOfEvents=this.dayEvent;
  }

  drop(event: CdkDragDrop<any[], any>) {

    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      this.sortEventsByStartHour();
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
      this.sortEventsByStartHour();
    }
  }

  sortEventsByStartHour()
  {
    this.listOfEvents.sort(function (a, b)
    {
      return compareAsc(parseISO(a.getTimeStart()),parseISO(b.getTimeStart()));
    })
  }
}
