<app-menu>
<div class="user-creator-container">
  <h1>Ajout d'un nouvel utilisateur</h1>
  <div class="user-creator-container__form-container">

    <label>Nom</label>
    <input type="text" class="user-creator-container__form-container__input">
    <label>Prénom</label>
    <input type="text" class="user-creator-container__form-container__input">
    <label>Email</label>
    <input type="text" class="user-creator-container__form-container__input">
    <label>Date de naissance:</label>
    <mat-form-field appearance="fill">
      <mat-label>Choisir une date:</mat-label>
      <input matInput [matDatepicker]="picker">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

  </div>
<div class="user-creator-container__form-container__button-row">
  <button class="user-creator-container__form-container__button-row__button-add" mat-raised-button>Ajouter utilisateur</button>
  <button class="user-creator-container__form-container__button-row__button-cancel" mat-raised-button>Annuler</button>
</div>

</div>
</app-menu>
