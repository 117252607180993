<app-menu>
<div class="password-changer-container">
  <mat-label>Entrez votre ancien mot de passe</mat-label>
  <input type="text" class="password-changer-container__input">
  <mat-label>Entrez votre nouveau mot de passe</mat-label>
  <input type="text" class="password-changer-container__input">
  <div class="password-changer-container__buttons-row">
    <button mat-raised-button class="password-changer-container__buttons-row__button-add" >Envoyer</button>
    <button mat-raised-button class="password-changer-container__buttons-row__button-cancel" >Annuler</button>
  </div>
</div>
</app-menu>
