import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ConfigLoaderService} from "./config-loader.service";
import {Config} from "../models/ConfigModel";

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  private config: Config;

  constructor(private http: HttpClient,configLoader: ConfigLoaderService)
  {
    this.config = configLoader.getConfigData();
  }

  fetchWrongFormattedAddresses()
  {
    return this.http.get<any>(this.config.host+'/api/address/fetchWrongFormattedAddresses').toPromise();
  }
}
