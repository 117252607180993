import {Subject} from "rxjs";
import {Educateur} from "../models/Educateur.model";
import {PrestationService} from "./prestation.service";
import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";


@Injectable()
export class EducateurService{
  private prestation: PrestationService;


  private educateurs: Educateur[] = [{
     "nom": "Mol",
     "prenom": "Sophie",
     "br": "Charleroi",
     "lundi": "342",
     "mardi": "342",
     "mercredi": "342",
     "jeudi": "342",
     "vendredi": "342",
     "samedi": "0",
     "dimanche": "0",
    id:1,
     prestations:[]
    }
     ,

    {
     "nom": "Georgoulis",
    "prenom": "Emmanuel",
     "br": "Charleroi",
    "lundi": "456",
    "mardi": "456",
    "mercredi": "456",
    "jeudi": "456",
    "vendredi": "456",
    "samedi": "0",
     "dimanche": "0",
    id:3,
        prestations:[ ]
   }
     ,

    {
    "nom": "Abrahamowicz",
    "prenom": "Camille",
    "br": "Brabant Wallon",
     "lundi": "342",
     "mardi": "342",
     "mercredi": "342",
     "jeudi": "342",
     "vendredi": "342",
     "samedi": "0",
    "dimanche": "0",
     id:5,

    prestations:[{
      "nomBeneficiaire": "Bechoux",
      "prenomBeneficiaire": "Ala\u00efs",
      "datePrestation": "2021-07-12",
      "heureDebut": "08:30:00",
      "heureFin": "13:30:00",
      "nomEducateur": "Desemberg",
      "prenomEducateur": "Camille",
      id:9898,
      idEducateur:5
    }]
   }

  ];
  educateurSubject  = new Subject<Educateur[]>();
  constructor(private httpClient: HttpClient){

  }
  emitEducateurs(){
    this.educateurSubject.next(this.educateurs.slice());
  }
  addEducateur(educateur: Educateur){
    this.educateurs.push(educateur);
    this.emitEducateurs();
  }


  getEducateurById(id: number) {
    const educateur = this.educateurs.find(
      (educateurObject) => {
        return educateurObject.id === id;
      }
    );
    return educateur;
  }

  getPrestationById(id:number,idPrestation:number){
    let educateur = this.getEducateurById(id);
    // @ts-ignore
    const prestation = educateur.prestations.find(
      (prestationObject)=>{
        return prestationObject.id === idPrestation
      }
    );
    return prestation;
  }

setPrestation(id: number,idPrestation:number,data:string){

  // @ts-ignore
 let educateur = this.getEducateurById(id);
 // @ts-ignore
  let prestation = this.getPrestationById(id,idPrestation);
  // @ts-ignore

  // @ts-ignore
  // prestation.prenomEducateur = educateur.prenom;
  // @ts-ignore
   prestation.datePrestation = data;
this.emitEducateurs();



}

setEducateur(presta:any[]){

      this.educateurs.forEach(function(value,key){
        const resultat = presta.filter( prestation => prestation.idEducateur === value.id)
        //value.prestations?.push(resultat);
        resultat.forEach(function (prest,cle){
          //@ts-ignore
          value.prestations.push(prest);
        }
      )
        console.log(value)

      })
    this.emitEducateurs();

     // console.log(presta);

}

  getEducateursFromServer(){

    this.httpClient
      .get<any[]>('http://127.0.0.1:8000/api/educateur/all/active')
      .subscribe(
        (response: any)=>{
          console.log(response);
          this.educateurs = response;
          this.emitEducateurs();
        },
        (error)=>{
          console.log("erreur de chargement " + error );
        }
      )
  }

}

