<div class="forgotten-password"></div>
<div class="forgotten-password__container">
  <p>Merci de bien vouloir indiquer votre adresse mail afin de vous envoyer la procédure à suivre pour réinitialiser votre mot de passe.</p>
  <label>Email</label>
  <input class="forgotten-password__input" id="mail" type="text" >
  <div class="forgotten-password__buttons-row">
    <button mat-raised-button class="forgotten-password__buttons-row__button" >Envoyer</button>
    <button mat-raised-button class="forgotten-password__buttons-row__button" >Annuler</button>
  </div>

</div>

