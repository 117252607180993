import {Subject} from "rxjs";
import {Injectable} from "@angular/core";
import {Prestation} from "../models/Prestation.model";

@Injectable()
export class PrestationService {

  prestationSubject = new Subject<any[]>();
  private prestations = [{
    "nomBeneficiaire": "Marsala",
    "prenomBeneficiaire": "Luna",
    "datePrestation": "2021-07-12",
    "heureDebut": "11:00:00",
    "heureFin": "18:00:00",
    "nomEducateur": "Melis",
    "prenomEducateur": "Massimo",
    id:1,
    idEducateur:9
  }, {
    "nomBeneficiaire": "Samhi",
    "prenomBeneficiaire": "Imane",
    "datePrestation": "2021-07-12",
    "heureDebut": "14:00:00",
    "heureFin": "21:00:00",
    "nomEducateur": "Georgoulis",
    "prenomEducateur": "Emmanuel",
    id:2,
    idEducateur: 9
  }, {
    "nomBeneficiaire": "Olivito",
    "prenomBeneficiaire": "Maurizio",
    "datePrestation": "2021-07-12",
    "heureDebut": "10:00:00",
    "heureFin": "14:00:00",
    "nomEducateur": "Gilsoul",
    "prenomEducateur": "Lucas",
    id:3,
    idEducateur: 12
  }, {
    "nomBeneficiaire": "Dos Santos",
    "prenomBeneficiaire": "Shayna",
    "datePrestation": "2021-07-12",
    "heureDebut": "09:00:00",
    "heureFin": "16:00:00",
    "nomEducateur": "Saive",
    "prenomEducateur": "Caroline",
    id:4,
    idEducateur: 3
  }, {
    "nomBeneficiaire": "Meinguet-Determe",
    "prenomBeneficiaire": "Xavier",
    "datePrestation": "2021-07-12",
    "heureDebut": "09:00:00",
    "heureFin": "17:00:00",
    "nomEducateur": "Desemberg",
    "prenomEducateur": "Fran\u00e7ois",
    id:5,
    idEducateur: 10
  }, {
    "nomBeneficiaire": "De Ryckere",
    "prenomBeneficiaire": "Aymeric",
    "datePrestation": "2021-07-13",
    "heureDebut": "14:00:00",
    "heureFin": "18:00:00",
    "nomEducateur": "Georgoulis",
    "prenomEducateur": "Emmanuel",
    id:6,
    idEducateur: 10
  }, {
    "nomBeneficiaire": "Wiame",
    "prenomBeneficiaire": "Amaury",
    "datePrestation": "2021-07-13",
    "heureDebut": "09:00:00",
    "heureFin": "12:00:00",
    "nomEducateur": "Gilsoul",
    "prenomEducateur": "Lucas",
    id:7,
    idEducateur: 6
  }, {
    "nomBeneficiaire": "Giffroy",
    "prenomBeneficiaire": "Thierry",
    "datePrestation": "2021-07-13",
    "heureDebut": "14:00:00",
    "heureFin": "17:00:00",
    "nomEducateur": "Gilsoul",
    "prenomEducateur": "Lucas",
    id:8,
    idEducateur: 7
  }, {
    "nomBeneficiaire": "Samhi",
    "prenomBeneficiaire": "Imane",
    "datePrestation": "2021-07-13",
    "heureDebut": "14:00:00",
    "heureFin": "21:00:00",
    "nomEducateur": "Dubois",
    "prenomEducateur": "Aur\u00e9lie",
    id:8,
    idEducateur: 6
  }, {
    "nomBeneficiaire": "Descampe",
    "prenomBeneficiaire": "Diego",
    "datePrestation": "2021-07-13",
    "heureDebut": "12:00:00",
    "heureFin": "18:00:00",
    "nomEducateur": "Saive",
    "prenomEducateur": "Caroline",
    id:9,
    idEducateur: 3
  }, {
    "nomBeneficiaire": "Debrulle",
    "prenomBeneficiaire": "Camille",
    "datePrestation": "2021-07-13",
    "heureDebut": "16:45:00",
    "heureFin": "20:00:00",
    "nomEducateur": "Lejeune",
    "prenomEducateur": "Alice",
    id:10,
    idEducateur: 11
  }, {
    "nomBeneficiaire": "Rotolo",
    "prenomBeneficiaire": "Mat\u00e9o",
    "datePrestation": "2021-07-13",
    "heureDebut": "14:00:00",
    "heureFin": "18:00:00",
    "nomEducateur": "Colinet",
    "prenomEducateur": "Annabelle",
    id:11,
    idEducateur: 3
  }, {
    "nomBeneficiaire": "Marsala",
    "prenomBeneficiaire": "Luna",
    "datePrestation": "2021-07-14",
    "heureDebut": "11:00:00",
    "heureFin": "18:00:00",
    "nomEducateur": "Melis",
    "prenomEducateur": "Massimo",
    id:12,
    idEducateur: 7
  }, {
    "nomBeneficiaire": "Lambert",
    "prenomBeneficiaire": "Valentin",
    "datePrestation": "2021-07-14",
    "heureDebut": "08:30:00",
    "heureFin": "13:30:00",
    "nomEducateur": "Gilsoul",
    "prenomEducateur": "Lucas",
    id:13,
    idEducateur: 12
  }, {
    "nomBeneficiaire": "Samhi",
    "prenomBeneficiaire": "Imane",
    "datePrestation": "2021-07-14",
    "heureDebut": "14:00:00",
    "heureFin": "19:00:00",
    "nomEducateur": "Gilsoul",
    "prenomEducateur": "Lucas",
    id:14,
    idEducateur: 2
  }, {
    "nomBeneficiaire": "Haegelsteens",
    "prenomBeneficiaire": "Simon",
    "datePrestation": "2021-07-14",
    "heureDebut": "10:30:00",
    "heureFin": "17:30:00",
    "nomEducateur": "Halot",
    "prenomEducateur": "Alixe",
    id:15,
    idEducateur: 5
  }, {
    "nomBeneficiaire": "Olivito",
    "prenomBeneficiaire": "Maurizio",
    "datePrestation": "2021-07-14",
    "heureDebut": "09:00:00",
    "heureFin": "12:00:00",
    "nomEducateur": "Dubois",
    "prenomEducateur": "Aur\u00e9lie",
    id:16,
    idEducateur: 7
  }, {
    "nomBeneficiaire": "Debrulle",
    "prenomBeneficiaire": "Camille",
    "datePrestation": "2021-07-14",
    "heureDebut": "16:45:00",
    "heureFin": "19:45:00",
    "nomEducateur": "Dubois",
    "prenomEducateur": "Aur\u00e9lie",
    id:17,
    idEducateur: 3
  }, {
    "nomBeneficiaire": "De Vos",
    "prenomBeneficiaire": "Ambre",
    "datePrestation": "2021-07-14",
    "heureDebut": "14:00:00",
    "heureFin": "18:15:00",
    "nomEducateur": "Lejeune",
    "prenomEducateur": "Alice",
    id:18,
    idEducateur: 8
  }, {
    "nomBeneficiaire": "Devos",
    "prenomBeneficiaire": "Steve",
    "datePrestation": "2021-07-14",
    "heureDebut": "13:00:00",
    "heureFin": "17:45:00",
    "nomEducateur": "Colinet",
    "prenomEducateur": "Annabelle",
    id:19,
    idEducateur: 6
  }, {
    "nomBeneficiaire": "Meinguet-Determe",
    "prenomBeneficiaire": "Xavier",
    "datePrestation": "2021-07-14",
    "heureDebut": "14:30:00",
    "heureFin": "18:00:00",
    "nomEducateur": "Desemberg",
    "prenomEducateur": "Fran\u00e7ois",
    id:20,
    idEducateur: 11
  }, {
    "nomBeneficiaire": "Dos Santos",
    "prenomBeneficiaire": "Shayna",
    "datePrestation": "2021-07-15",
    "heureDebut": "09:00:00",
    "heureFin": "16:00:00",
    "nomEducateur": "Saive",
    "prenomEducateur": "Caroline",
    id:21,
    idEducateur: 11
  }, {
    "nomBeneficiaire": "Giliotta",
    "prenomBeneficiaire": "Robyne",
    "datePrestation": "2021-07-15",
    "heureDebut": "08:00:00",
    "heureFin": "12:00:00",
    "nomEducateur": "Dubois",
    "prenomEducateur": "Aur\u00e9lie",
    id:22,
    idEducateur: 9
  }
  // , {
  //   "nomBeneficiaire": "De Potter",
  //   "prenomBeneficiaire": "Manon",
  //   "datePrestation": "2021-07-15",
  //   "heureDebut": "13:00:00",
  //   "heureFin": "19:00:00",
  //   "nomEducateur": "Dubois",
  //   "prenomEducateur": "Aur\u00e9lie"
  // }, {
  //   "nomBeneficiaire": "Devos",
  //   "prenomBeneficiaire": "Steve",
  //   "datePrestation": "2021-07-15",
  //   "heureDebut": "12:00:00",
  //   "heureFin": "17:00:00",
  //   "nomEducateur": "Lejeune",
  //   "prenomEducateur": "Alice"
  // }, {
  //   "nomBeneficiaire": "Derweduwen",
  //   "prenomBeneficiaire": "Margaux",
  //   "datePrestation": "2021-07-16",
  //   "heureDebut": "08:15:00",
  //   "heureFin": "13:30:00",
  //   "nomEducateur": "Melis",
  //   "prenomEducateur": "Massimo"
  // }, {
  //   "nomBeneficiaire": "Wiame",
  //   "prenomBeneficiaire": "Amaury",
  //   "datePrestation": "2021-07-16",
  //   "heureDebut": "09:00:00",
  //   "heureFin": "12:00:00",
  //   "nomEducateur": "Georgoulis",
  //   "prenomEducateur": "Emmanuel"
  // }, {
  //   "nomBeneficiaire": "Turk",
  //   "prenomBeneficiaire": "Haris",
  //   "datePrestation": "2021-07-16",
  //   "heureDebut": "17:00:00",
  //   "heureFin": "20:00:00",
  //   "nomEducateur": "Georgoulis",
  //   "prenomEducateur": "Emmanuel"
  // }, {
  //   "nomBeneficiaire": "Beyens",
  //   "prenomBeneficiaire": "Lucy",
  //   "datePrestation": "2021-07-16",
  //   "heureDebut": "18:00:00",
  //   "heureFin": "22:00:00",
  //   "nomEducateur": "Abrahamowicz",
  //   "prenomEducateur": "Camille"
  // }, {
  //   "nomBeneficiaire": "Brimbois-Santangelo",
  //   "prenomBeneficiaire": "Francesco",
  //   "datePrestation": "2021-07-16",
  //   "heureDebut": "11:00:00",
  //   "heureFin": "19:00:00",
  //   "nomEducateur": "Halot",
  //   "prenomEducateur": "Alixe"
  // }, {
  //   "nomBeneficiaire": "Samhi",
  //   "prenomBeneficiaire": "Imane",
  //   "datePrestation": "2021-07-16",
  //   "heureDebut": "10:00:00",
  //   "heureFin": "16:00:00",
  //   "nomEducateur": "Dubois",
  //   "prenomEducateur": "Aur\u00e9lie"
  // }, {
  //   "nomBeneficiaire": "Debrulle",
  //   "prenomBeneficiaire": "Camille",
  //   "datePrestation": "2021-07-16",
  //   "heureDebut": "16:45:00",
  //   "heureFin": "19:45:00",
  //   "nomEducateur": "Lejeune",
  //   "prenomEducateur": "Alice"
  // }, {
  //   "nomBeneficiaire": "Cannarozzo",
  //   "prenomBeneficiaire": "Gabriel",
  //   "datePrestation": "2021-07-17",
  //   "heureDebut": "20:00:00",
  //   "heureFin": "01:00:00",
  //   "nomEducateur": "Melis",
  //   "prenomEducateur": "Massimo"
  // }, {
  //   "nomBeneficiaire": "Turk",
  //   "prenomBeneficiaire": "Haris",
  //   "datePrestation": "2021-07-17",
  //   "heureDebut": "08:00:00",
  //   "heureFin": "13:30:00",
  //   "nomEducateur": "Georgoulis",
  //   "prenomEducateur": "Emmanuel"
  // }, {
  //   "nomBeneficiaire": "Samhi",
  //   "prenomBeneficiaire": "Imane",
  //   "datePrestation": "2021-07-17",
  //   "heureDebut": "14:00:00",
  //   "heureFin": "21:00:00",
  //   "nomEducateur": "Gilsoul",
  //   "prenomEducateur": "Lucas"
  // }, {
  //   "nomBeneficiaire": "Van Dorpe",
  //   "prenomBeneficiaire": "Gabrielle",
  //   "datePrestation": "2021-07-17",
  //   "heureDebut": "15:00:00",
  //   "heureFin": "18:00:00",
  //   "nomEducateur": "Abrahamowicz",
  //   "prenomEducateur": "Camille"
  // }, {
  //   "nomBeneficiaire": "Descampe",
  //   "prenomBeneficiaire": "Diego",
  //   "datePrestation": "2021-07-17",
  //   "heureDebut": "12:00:00",
  //   "heureFin": "18:00:00",
  //   "nomEducateur": "Saive",
  //   "prenomEducateur": "Caroline"
  // }, {
  //   "nomBeneficiaire": "Libert ",
  //   "prenomBeneficiaire": "Cl\u00e9mence",
  //   "datePrestation": "2021-07-17",
  //   "heureDebut": "09:00:00",
  //   "heureFin": "12:30:00",
  //   "nomEducateur": "Halot",
  //   "prenomEducateur": "Alixe"
  // }, {
  //   "nomBeneficiaire": "Cr\u00e9pin",
  //   "prenomBeneficiaire": "Charlotte",
  //   "datePrestation": "2021-07-17",
  //   "heureDebut": "13:00:00",
  //   "heureFin": "18:00:00",
  //   "nomEducateur": "Halot",
  //   "prenomEducateur": "Alixe"
  // }, {
  //   "nomBeneficiaire": "Depasse",
  //   "prenomBeneficiaire": "Erika",
  //   "datePrestation": "2021-07-17",
  //   "heureDebut": "16:00:00",
  //   "heureFin": "23:45:00",
  //   "nomEducateur": "Dubois",
  //   "prenomEducateur": "Aur\u00e9lie"
  // }, {
  //   "nomBeneficiaire": "Debrulle",
  //   "prenomBeneficiaire": "Camille",
  //   "datePrestation": "2021-07-17",
  //   "heureDebut": "11:45:00",
  //   "heureFin": "20:00:00",
  //   "nomEducateur": "Lejeune",
  //   "prenomEducateur": "Alice"
  // }, {
  //   "nomBeneficiaire": "Piot",
  //   "prenomBeneficiaire": "Daphn\u00e9e",
  //   "datePrestation": "2021-07-18",
  //   "heureDebut": "14:00:00",
  //   "heureFin": "20:00:00",
  //   "nomEducateur": "Melis",
  //   "prenomEducateur": "Massimo"
  // }, {
  //   "nomBeneficiaire": "Debrulle",
  //   "prenomBeneficiaire": "Camille",
  //   "datePrestation": "2021-07-18",
  //   "heureDebut": "10:00:00",
  //   "heureFin": "20:00:00",
  //   "nomEducateur": "Georgoulis",
  //   "prenomEducateur": "Emmanuel"
  // }, {
  //   "nomBeneficiaire": "Turk",
  //   "prenomBeneficiaire": "Haris",
  //   "datePrestation": "2021-07-18",
  //   "heureDebut": "17:00:00",
  //   "heureFin": "19:30:00",
  //   "nomEducateur": "Gilsoul",
  //   "prenomEducateur": "Lucas"
  // }, {
  //   "nomBeneficiaire": "Vandereck",
  //   "prenomBeneficiaire": "Michael",
  //   "datePrestation": "2021-07-18",
  //   "heureDebut": "09:30:00",
  //   "heureFin": "17:00:00",
  //   "nomEducateur": "Abrahamowicz",
  //   "prenomEducateur": "Camille"
  // }, {
  //   "nomBeneficiaire": "Cr\u00e9pin",
  //   "prenomBeneficiaire": "Charlotte",
  //   "datePrestation": "2021-07-18",
  //   "heureDebut": "14:00:00",
  //   "heureFin": "18:00:00",
  //   "nomEducateur": "Saive",
  //   "prenomEducateur": "Caroline"
  // }, {
  //   "nomBeneficiaire": "Thomassin",
  //   "prenomBeneficiaire": "Juliette",
  //   "datePrestation": "2021-07-18",
  //   "heureDebut": "11:00:00",
  //   "heureFin": "19:00:00",
  //   "nomEducateur": "Halot",
  //   "prenomEducateur": "Alixe"
  // }, {
  //   "nomBeneficiaire": "Thewys",
  //   "prenomBeneficiaire": "Edouard",
  //   "datePrestation": "2021-07-18",
  //   "heureDebut": "10:00:00",
  //   "heureFin": "17:30:00",
  //   "nomEducateur": "Lejeune",
  //   "prenomEducateur": "Alice"
  // }, {
  //   "nomBeneficiaire": "Neu",
  //   "prenomBeneficiaire": "Edouard",
  //   "datePrestation": "2021-07-18",
  //   "heureDebut": "09:00:00",
  //   "heureFin": "17:00:00",
  //   "nomEducateur": "Desemberg",
  //   "prenomEducateur": "Fran\u00e7ois"
  // }, {
  //   "nomBeneficiaire": "Bechoux",
  //   "prenomBeneficiaire": "Ala\u00efs",
  //   "datePrestation": "2021-07-13",
  //   "heureDebut": "08:30:00",
  //   "heureFin": "13:15:00",
  //   "nomEducateur": "Desemberg",
  //   "prenomEducateur": "Fran\u00e7ois"
  // }, {
  //   "nomBeneficiaire": "Bechoux",
  //   "prenomBeneficiaire": "Ala\u00efs",
  //   "datePrestation": "2021-07-14",
  //   "heureDebut": "08:30:00",
  //   "heureFin": "13:30:00",
  //   "nomEducateur": "Desemberg",
  //   "prenomEducateur": "Fran\u00e7ois"
  // }, {
  //   "nomBeneficiaire": "Giffroy",
  //   "prenomBeneficiaire": "Thierry",
  //   "datePrestation": "2021-07-16",
  //   "heureDebut": "14:00:00",
  //   "heureFin": "17:00:00",
  //   "nomEducateur": "Melis",
  //   "prenomEducateur": "Massimo"
  // }
    ];

  constructor() {
  }

  emitPrestationSubject() {
    this.prestationSubject.next(this.prestations.slice())
  }

  // getPrestationById(id: number) {
  //   const prestation = this.prestations.find(
  //     (prestationObject) => {
  //       return prestationObject.id === id;
  //     }
  //   );
  //   return prestation;
  // }

  addPrestation(
    id:number,
    nomBeneficiaire: string,
                prenomBeneficiaire: string,
                datePrestation: string,

  heureDebut: string
,
  heureFin: string
,
  nomEducateur: string
,
  prenomEducateur: string,
    idEducateur:number
) {
  const
  prestationObject = {
    id:0,
    nomBeneficiaire: '',
    prenomBeneficiaire: '',
    datePrestation: '',
    heureDebut: '',
    heureFin: '',
    nomEducateur: '',
    prenomEducateur: '',
    idEducateur:0
  };
  prestationObject.id = id;
  prestationObject.prenomBeneficiaire = prenomBeneficiaire;
  prestationObject.nomBeneficiaire = nomBeneficiaire;
  prestationObject.datePrestation = datePrestation;
  prestationObject.heureDebut = heureDebut;
  prestationObject.heureFin = heureFin;
  prestationObject.nomEducateur = nomEducateur;
  prestationObject.prenomBeneficiaire = prenomEducateur;
  prestationObject.idEducateur = idEducateur;
  this.prestations.push(prestationObject);
  this.emitPrestationSubject();
}


// setPrestation(
//   id:number
// ){
//   const
//     prestationObject = {
//       id:0,
//       nomBeneficiaire: '',
//       prenomBeneficiaire: '',
//       datePrestation: '',
//       heureDebut: '',
//       heureFin: '',
//       nomEducateur: '',
//       prenomEducateur: ''
//     };
//   prestationObject.id = id;
//   prestationObject.prenomBeneficiaire = prenomBeneficiaire;
//   prestationObject.nomBeneficiaire = nomBeneficiaire;
//   prestationObject.datePrestation = datePrestation;
//   prestationObject.heureDebut = heureDebut;
//   prestationObject.heureFin = heureFin;
//   prestationObject.nomEducateur = nomEducateur;
//   prestationObject.prenomBeneficiaire = prenomEducateur;
//
//   this.emitPrestationSubject();
// }
}
