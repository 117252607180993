import {SchedulerEvent} from "./interfaces/scheduler-event";

export class PrestationEvent implements SchedulerEvent{
  private date: string;
  private name: string;
  private timeStart: string;
  private timeEnd: string;
  private mainEducateurId: number;

  constructor(date : string,name:string,timeStart:string,timeEnd:string,mainEducateurId:number) {
    this.date = date;
    this.name=name;
    this.timeStart= timeStart;
    this.timeEnd = timeEnd;
    this.mainEducateurId = mainEducateurId;
  }
  getDate()
  {
    return this.date.slice(0,10);
  }
  getName()
  {
    return this.name;
  }
  getHoraire()
  {
    return this.timeStart.slice(11,16)+'-'+this.timeEnd.slice(11,16);
  }
  getTimeStart()
  {
    return this.timeStart;
  }
  getEducateurId():number
  {
    return this.mainEducateurId;
  }
}
