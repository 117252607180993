import { Component, OnInit,Input } from '@angular/core';
import {PrestationService} from "../services/prestation.service";
import {CdkDragDrop, moveItemInArray,transferArrayItem} from "@angular/cdk/drag-drop";
@Component({
  selector: 'app-prestation',
  templateUrl: './prestation.component.html',
  styleUrls: ['./prestation.component.scss']
})
export class PrestationComponent implements OnInit {

  @Input()  nomBeneficiaire: string;
  @Input() prenomBeneficiaire: string;
  @Input() datePrestation: string;
  @Input() heureDebut: string;
  @Input() heureFin: string;
  @Input() nomEducateur:string;
  @Input() prenomEducateur:string;



  constructor(private prestationService: PrestationService) { }

  ngOnInit(): void {

  }

  getNomBeneficiaire(){
    return this.nomBeneficiaire;
  }




}

