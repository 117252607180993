import {Period} from "../app/interfaces/period";
import {addDays, eachDayOfInterval} from "date-fns";
import {Day} from "./day";

export class WeekPeriod implements Period{
  private endTime: Date;
  private startTime: Date;
  private days: Day[];
  constructor(startTime) {
    this.startTime=startTime;
    this.endTime = addDays(startTime,6);
    this.days=[];
       for (let day of eachDayOfInterval({start:this.startTime,end:this.endTime})) {
      this.days.push(new Day(day));
    }
  }
  getDaysOfPeriod(): Day[] {

    return this.days;
  }

  getNextPeriod(): Period {
    return undefined;
  }

  getPreviousPeriod(): Period {
    return undefined;
  }

  getFirstDayOfPeriod(): Day {
    return this.getDaysOfPeriod()[0];
  }
  getLastDayOfPeriod(): Day {
    return this.getDaysOfPeriod().slice(-1)[0];
  }


}
