import { Injectable } from '@angular/core';
import {ConfigLoaderService} from "./config-loader.service";
import {Config} from "../models/ConfigModel";
import {HttpClient} from "@angular/common/http";
import {SchedulerEvent} from "../interfaces/scheduler-event";
import {PrestationEvent} from "../prestation-event";

@Injectable({
  providedIn: 'root'
})
export class SchedulerService {
private config: Config;
schedulerEvents:SchedulerEvent[];
  constructor(configLoader: ConfigLoaderService,private http: HttpClient) {
    this.config = configLoader.getConfigData();
    this.schedulerEvents=[];
  }

  public fetchEducateurToLoadInScheduler(startOfWeek)
  {
    return this.http.get<any>(this.config.host+'/api/scheduler/educateurToLoad/'+startOfWeek).toPromise();
  }
     async fetchPrestationsBetweenTwoDates(startDate, endDate)
  {
     await this.http.get<SchedulerEvent[]>(this.config.host+'/api/scheduler/fetchPrestationBetweenTwoDates/'+startDate+'/'+endDate).toPromise()
      .then((events)=>
      {
        for (let event of events) {

          this.schedulerEvents.push(new PrestationEvent(event['date'],event['beneficiaryFirstName']+' '+event['beneficiaryLastName'],event['startTime'],event['endTime'],event['educateurId']));
        }
      });
  }

  public  getPrestationsByEducateurId(educateurId)
  {
    return this.schedulerEvents.filter(item=>item.getEducateurId() == educateurId );
  }
}
