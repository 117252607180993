import {Component, OnInit} from '@angular/core';
import _ from "lodash";
import {Beneficiary} from "../../model/Beneficiary";
import {InvoiceService} from "../services/invoice.service";


@Component({
  selector: 'app-generate-facture',
  templateUrl: './generate-facture.component.html',
  styleUrls: ['./generate-facture.component.scss']
})
export class GenerateFactureComponent implements OnInit {
  private currentDate: Date;
  bapBeneficiaires;
  repitBeneficiaires;
  selectedYear: number;
  selectedMonth: number;
  months = [
    {name: "Janvier", value: 1},
    {name: "Février", value: 2},
    {name: "Mars", value: 3},
    {name: "Avril", value: 4},
    {name: "Mai", value: 5},
    {name: "Juin", value: 6},
    {name: "Juillet", value: 7},
    {name: "Août", value: 8},
    {name: "Septembre", value: 9},
    {name: "Octobre", value: 10},
    {name: "Novembre", value: 11},
    {name: "Décembre", value: 12},
  ];
  years = [
    {value: 2021},
    {value: 2022}
  ];

  constructor( private invoiceService: InvoiceService) {
    this.invoiceService = invoiceService;
  }

  ngOnInit(): void {
    this.currentDate = new Date();
    this.selectedYear= this.currentDate.getFullYear();
    this.selectedMonth = this.currentDate.getMonth()+1;
    this.loadBeneficiairesToInvoiceByMonthAndYear(this.selectedMonth , this.selectedYear);
  }

  private async loadBeneficiairesToInvoiceByMonthAndYear(month: number, year: number) {

    this.invoiceService.fetchBeneficiairesByMonth(month, year).then((data => {
      this.bapBeneficiaires = this.getBeneficiaryCardsToLoad(data.BAP);
      this.repitBeneficiaires = this.getBeneficiaryCardsToLoad(data.REPIT);
    }));

  }

  public getBeneficiaryCardsToLoad(beneficiairies) {
    let cards = [];
    for (let beneficiary of beneficiairies) {
      if (beneficiary.mother) {
        cards.push(this.createBeneficiaryCard(beneficiary.personne.NOM, beneficiary.personne.PRENOM, 'M', beneficiary.personne.ID, beneficiary.mother.ID));
      }
      if (beneficiary.father) {
        cards.push(this.createBeneficiaryCard(beneficiary.personne.NOM, beneficiary.personne.PRENOM, 'P', beneficiary.personne.ID, beneficiary.father.ID));
      }
      if (beneficiary.third_contact) {
        cards.push(this.createBeneficiaryCard(beneficiary.personne.NOM, beneficiary.personne.PRENOM, 'A', beneficiary.personne.ID, beneficiary.third_contact.ID));
      }
    }
    return _.sortBy(cards, ['lastName']);
  }

  public createBeneficiaryCard(lastName, firstName, contact, beneficiaryId, contactId) {
    let card = new Beneficiary();
    card.firstName = firstName;
    if (lastName.length > 10) {
      card.lastName = lastName.slice(0, 12);
    } else {
      card.lastName = lastName;
    }
    card.contact = contact
    card.beneficiaryId = beneficiaryId;
    card.contactId = contactId;

    return card;
  }

  public updateBeneficiaries(month: number, year: number) {
    this.loadBeneficiairesToInvoiceByMonthAndYear(month, year);
  }

}
