import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-forget-pass-word',
  templateUrl: './forget-pass-word.component.html',
  styleUrls: ['./forget-pass-word.component.scss']
})
export class ForgetPassWordComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
